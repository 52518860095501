.bulk-purchase-ui {
	min-width:		991px;
	font-family: 	"Lato", sans-serif;
	& > .bulk-form {
		padding:			20px;
		background-color:	#F8F8FF;
		border-bottom:		2px solid #2CB6FA;
		& > .title  {
			font-weight:	700;
			font-size:		16px;
			color:			rgba(0,0,0,0.8);
			padding-bottom: 10px;
		}
		table.bulk-form {
			width:	100%;
			margin:	3rem 0;
			& > thead {
				& > tr {
					& > th {
						font-weight:	500;
						font-size:		13px;
						color:			rgba(0,0,0,0.7);
						padding:		5px;
						text-align:		left;
						&.center {
							text-align: center;
						}
					}
				}
			}
			& > tbody {
				& > tr {
					& > td {
						text-align:		left;
						font-size:		12px;
						color:			#333;
						input {
							color:				rgba(0,0,0,0.8);
							border:				0;
							background-color:	transparent;
							border-bottom:		1px solid rgba(0,0,0,0.8);
							width:				100%;
							box-sizing:			border-box;
							padding:			5px;
							box-shadow:			none;
							border-radius:		0;
							&.center {
								text-align: 	center;
							}
						}
						&.center {
							text-align: 	center;
						}
						&.empty {
							text-align:		center;
							padding:		10px;
							font-weight:	400;
							font-size:		11px;
							color:			rgba(0,0,0,0.7);
						}
						&.error {
							input {
								border-bottom:		1px solid rgba(200,0,0,0.8);
							}
						}
						.ui-list {
							z-index:	999;
						}
						.ui-dropdown {
							.label {
								padding:	5px 10px;
								font-size:	10px;
							}
						}
						&.action {
							text-align: center;
							.fa-times {
								color:		#373e5c;
								font-size:	24px;
							}
						}
					}
				}
			}
		}
		.password-confirm-container {
			margin:		8px 0;
			.title {
				font-weight: 	700;
    			font-size: 		16px;
    			color: 			rgba(0, 0, 0, 0.8);
    			padding-bottom: 10px;
			}
			input {
				margin-right: 	8px;
				padding: 		9px 15px;
				background: 	#e8f0fe;
				color: 			#444;
				border: 		none;
				border-radius: 	3px;
				box-sizing:	 	border-box;
				box-shadow: 	inset 0 2px 3px rgba(0, 0, 0, 0.03);
				outline: 		none;
				&.error {
					border: 		none !important;
    				background: 	#ffe6e6 !important;
    				box-shadow: 	0 2px 3px rgba(198, 40, 40, 0.2) !important;
				}
			}
		}
		.ctas {
			padding:		10px 0;
			text-align:		center;
			& > div {
				display:			inline-block;
				margin-right:		8px;
				padding:			10px 20px;
				background-color:	#1EB1FA;
				color:				#ffffff;
				border-radius:		5px;
				transition:			.25s ease-in-out;
				&:hover {
					background:	#128ef2;
				}
				&.disabled {
					background-color:	rgba(0,0,0, 0.1);
					color:				rgba(0,0,0, 0.5);
					cursor:				default;
				}
			}
			&.right {
				text-align:	right;
			}
			&.left {
				text-align:	left;
			}
			
		}
	}
}

.balance-container {
	margin:		0 3rem 1rem 3rem;
	& > div {
		&:first-child {
			margin-right:	3rem;
		}
	}
	.header {
		margin-bottom:	16px;
		color:			#1eb1fa;
		font-size:		20px;
		font-weight:	500;
	}
	.value {
		color:			#566481;
		font-size:		30px;
		font-weight:	600;
	}
}