.offers-widgets {
	display:		flex;
	flex-wrap:		wrap;
    align-items:	stretch;
    justify-content:flex-start;
	padding:		10px;
	overflow:		hidden;
     & > div {
		flex:		25%;
		
		width:				100%;
		height:				100%;
		display:			inline-block;
		text-align:			center;
		
		& > img.giftcard-image {
			/* deprecated */
			width:	100%;
		}
		& > div.giftcard-image {
			background-size:	contain;
			background-repeat:	no-repeat;
			background-position:center center;
			width:				165px;
			height:				104px;
			margin:				0 auto;
			border-radius:		10px;
			border:				1px solid #1eb1fa;
			// box-shadow:		0 3px 6px rgba(214, 214, 214,1);
			box-shadow:			0px 2px 1px #1eb1fa;
		}
		
		& > .bonus {
			text-align:		center;
			color:			rgba(99, 182, 38, 1);
			font-size:		12px;
			font-weight:	900;
			padding:		0 10px 10px;
		}
	}
}




.merchants {
	.page-container {
		// padding:	0 25px;
	}
	
	.page-banner {
		padding:			50px;
		margin-bottom:		25px;
		background-color:	#fff;
		&.dashboard {
			display:			flex;
			align-items:		center;
			flex-wrap:			wrap;
			justify-content: 	space-between;
			margin:				0 0 16px 0;
			padding:			30px 16px;
			background:			linear-gradient(#eff5fd, #ffffff);
			border:				none;
			@media screen and (max-width: 480px) {
				justify-content:	center;
			}
			.title {
				margin-bottom:	10px;
				// color:			#ffffff;
				color:			#566481;
				@media screen and (max-width: 480px) {
					font-size:	20px;
					text-align:	center;
				}
			}
			.subtitle {
				margin-bottom:		0;
				margin-top:			0;
				color:				#566481;
				font-weight:		300;
				@media screen and (max-width: 576px) {
					margin-bottom:	30px;
					font-size:		16px;
					text-align:		center;
				}
			}
			.cta {
				display:			flex;
				justify-content: 	center;
				align-items:		center;
				margin:				0;
				font-weight: 		700;
				@media screen and (max-width: 480px) {
					width:		100%;
					margin:		0 auto;
					font-size:	16px;
					text-align:	center;
				}
				.fa-plus {
					margin-right:	2px;
				}
			}
		}
		.title {
			display:		flex;
			align-items: 	center;
			margin-bottom: 	10px;
			font-size:		24px;
			font-weight:	600;
			color:			#566481;
			line-height:	0.97;
		}
		.subtitle {
			font-size:		18px;
			font-weight:	normal;
			color:			#566481;
			line-height:	1.35;
			margin-bottom:	15px;
		}
		.cta {
			margin-top:			20px;
			cursor:				pointer;
			display:			inline-block;
			vertical-align:		middle;
			text-decoration:	none;
			border-radius:		3px;
			padding:			0 25px;
			background-color:	#56c135;
			color:				#ffffff;
			font-size:			17px;
			line-height:		45px;
			letter-spacing:		normal;
			font-weight:		normal;
			font-stretch: 		normal;
			transition:			transform 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
			position:			relative;
			&:hover {
				background-color:	#49a02f;
    			text-decoration: 	none;
			}
		}
	}
	
	
	.campaign-list {
		border-radius:	15px;
		background: 	#ffffff;
		margin:			0 1rem;
		.header {
			display:			flex;
			justify-content: 	space-between;
			align-items: 		center;
			padding:			1rem;
			.title {
				display:		flex;
				align-items: 	center;
				font-size:		24px;
				font-weight:	500;
				color: 			#566481;
				line-height:	40px;
				font-weight: 	600;
				font-style: 	normal;
				font-stretch: 	normal;
				line-height: 	1.21;
				letter-spacing: normal;
				text-align: 	left;
				/* padding-left:	8px; */
			}
			.arrow {
				margin:		0 10px;
				color:		#566481;
				font-size:	27px;
			}
		}
	}
	
	.image-guideline {
		width:				75%;
		margin:				7px auto;
		background-color:	rgba(255, 224, 130, 0.5);
		border:				1px solid rgba(255, 183, 77, 0.8);
		padding:			10px;
		ul {
			padding:			0;
			margin:				0 10px;
			li {
				list-style-type:	square;
				font-size:			12px;
				font-weight:		500;
				padding:			0;
				margin:				0;
				strong {
					font-weight:	900;
				}
			}
		}
	}
}

.creative-list {
	max-height:	500px;
	max-width:	800px;
	overflow:	auto;
	.creatives {
		display:			flex;
		justify-content: 	space-evenly;
		border:				1px solid #ebebeb;
		border-radius:		3px;
		flex-wrap:			wrap;
		box-sizing:			border-box;
		& > div {
			display: 		inline-block;
			padding: 		5px;
			border-radius: 	2px;
			box-sizing:		border-box;
			border:			4px solid #dddddd;
			&.selected {
				border-color:		#1eb1fa;
			}
			.name {
				width:		10px;
				height:		10px;
				bottom:		-5px;
				left:		-5px;
				background:		#dddddd;
				& > span {
					position: 	absolute;
					top:		0;
					left:		0;
					font-size:	18px;
				}
				&.selected {
					background: #1eb1fa;
				}
			}
			&:hover {
				background:		#e3f2fd;
				cursor:			pointer !important;
			}
		}
	}
}

.creatives {
	position:			relative;
	width:				100%;
	min-width:			95%;
	display:			flex;
	flex-direction:		row;
	flex-wrap:			nowrap;
	overflow-y:			hidden;
	overflow-x:			auto;
	& > div {
		display:			inline-block;
		// margin:			10px 10px 10px 0;
		margin:				5px;
		border-radius:		2px;
		transition:			0.2s ease-in;
		& > div {
			background-size:	cover;
			background-repeat:	no-repeat;
			background-position:center center;
			position:			relative;
			width:				165px;
			height:				104px;
			border-radius:		10px;
			.checkbox {
				position: 	absolute;
				top:		5px;
				right:		5px;
				color:		#ffffff;
				font-size:	18px;
				.fa-check-square {
				}
				/*
				label {
					margin:		0 !important;
					padding:	10px !important;
					.checkmark {
						top: 			0 !important;
						right:			0 !important;
						background:		#ffffff !important;
						border:			1px solid #000000 !important;
						border-radius:	0 !important;
					}
				}*/
			}
			& > .name {
				position:	absolute;
				bottom:		0;
				left:		0;
				padding:	5px;
				// background:	#1eb1fa;
				background:	#1eb1fa;
				color:		#ffffff;
				font-size:	14px;
				font-weight:600;
				text-shadow:0 0 10px rgba(0,0,0,0.6);
			}
			& > .close {
				position:	absolute;
				top:		5px;
				right:		5px;
				color:		#ffffff;
				font-size:	17px;
				font-weight:600;
				text-shadow:0 0 10px rgba(0,0,0,0.6);
			}
			& > .overlay {
				position:			absolute;
				top:				0;
				left:				0;
				width:				100%;
				height:				100%;
				border-radius:		10px;
				background-color:	rgba(0,0,0, 0.2);
			}
			&:hover {
				& > .overlay {
					background-color:	rgba(0,0,0, 0.5);
				}
			}
		}
	}
}
	
.ui-giftcards {
	background-color:	#ffffff;
}

.ui-giftcard-selector {
	& > .name {
		background-color:	rgba(226, 231, 237, 1);
		color:				#333333;
		font-size:			13px;
		font-weight:		700;
		padding:			10px;
		a {
			font-weight:	500;
			font-size:		11px;
		}
	}
	& > .values {
		color:				#333333;
		padding:			10px;
	}
}

table.grouped-list {
	.app-dialog & {
		width:	100%;
	}
	& > thead {
		& > tr {
			& > th {
				padding:		5px;
				font-size:		13px;
				font-weight:	900;
			}
		}
	}
	& > tbody {
		& > tr.group {
			background-color:	rgba(66, 165, 245, 0);
			transition:			background-color ease-out 0.3s;
			
			& > td {
				padding:		5px;
				font-size:		11px;
				font-weight:	500;
				color:			rgba(0,0,0,0.7);
				text-align:		left;
				vertical-align:	middle;
				
				&.caret {
					width:			20px;
					font-size:		18px;
				}
				&.name {
					width:			150px;
					font-size:		14px;
					font-weight:	600;
				}
				&.country {
					width:			30px;
					font-size:		14px;
					font-weight:	600;
				}
				&.currency {
					width:			30px;
					font-size:		14px;
					font-weight:	600;
				}
				&.action {
					text-align:		right;
				}
			}
			
			&:hover {
				background-color:	rgba(66, 165, 245, 0.2);
			}
			&.selected {
				background-color:	rgba(66, 165, 245, 0.3);
			}
		}
		& > tr.giftcards {
			& > td {
				& > table {
					width:		100%;
					& > thead {
						& > tr {
							& > th {
								padding:		5px;
								font-size:		12px;
								font-weight:	900;
								text-align:		left;
							}
						}
					}
					& > tbody {
						& > tr {
							background-color:	rgba(66, 165, 245, 0);
							transition:			background-color ease-out 0.3s;
							& > td {
								cursor:			default;
								vertical-align:	middle;
								padding:		5px;
								font-size:		12px;
								font-weight:	500;
								color:			rgba(0,0,0,0.7);
								text-align:		left;
								&.toggle {
									width:		60px;
									text-align:	right;
									.active {
										color:	rgba(156, 204, 101, 1);
									}
									.inactive {
										color:	rgba(229, 115, 115, 1);
									}
								}
							}
							
							&:hover {
								background-color:	rgba(66, 165, 245, 0.1);
							}
							
							&.disabled {
								background-color:	rgba(0, 0, 0, 0.08);
								& > td {
									color:			rgba(0,0,0,0.4);
								}
								&:hover {
									background-color:	rgba(0, 0, 0, 0.15);
								}
							}
						}
					}
				}
			}
			&.selected {
				background-color:	rgba(66, 165, 245, 0.1);
			}
		}
	}
}

.horizontal-divider {
	height:		1px;
	margin:		2px 0;
	// background:	#e6e6e6;
	background:	transparent;
}
.campaign-table {
	background-color:	rgba(255,255,255, 1);
	// border: 			1px solid #b7b7b7;
	// border-width:		0 1px 1px 1px;
	border-radius: 		4px;
	& > table {
		width:	100%;
		& > thead {
			// background-color: 	#e6e6e6;
			// background:			#dcdddf;
			background:				1px solid #e6eaea;
			// color:					#566481;
			color:					#c8c8c8;
			& > tr {
				padding:	16px 8px;
				& > th {
					padding:		18px 8px;
					padding-bottom:	36px;
					border-bottom:	1px solid #e6eaea;
					font-size:		17px;
					font-weight:	700;
					text-align:		center;
					&:first-child {
						padding-left:	1rem;
						text-align:		left;
					}
				}
			}
		}
		& > tbody {
			// border:			1px solid #dcdddf;
			border:			1px solid #e6eaea;
			border-width:	0 1px 1px 1px;
			& > tr {
				background-color:	rgba(66, 165, 245, 0);
				transition:			background-color ease-out 0.3s;
				&.grayed {
					& > td {
						color:	#e6eaea;
					}
				}
				&:last-child {
					td:first-child {
						// border-bottom-left-radius:	15px;
					}
					td:last-child {
						// border-bottom-right-radius:	15px;
					}
				}
				& > td {
					padding:		1rem 0.5rem;
					font-size:		16px;
					font-weight:	500;
					// color:			rgba(0,0,0,0.7);
					// color:			#e6eaea;
					color:			#566481;
					text-align:		center;
					user-select:	none;
					transition:		0.2s ease-in;
					// border-bottom:	1px solid #dcddff;
					// border-bottom:			1px solid #e6eaea;
					&:last-child {
						// border-right:	1px solid #dcddff;
						// border-right:			1px solid #e6eaea;
					}
					&.name {
						max-width:		150px;
						padding-left:	1rem;
						color:			#566481;
						font-weight:	600;
						// border-left:	1px solid #dcddff;
						// border-left:			1px solid #e6eaea;
						text-align:		left;
						white-space: 	nowrap;
						overflow: 		hidden;
						text-overflow: 	ellipsis;
					}
					&.edit {
						min-width:				120px;
						span {
							display: 			flex;
							justify-content: 	center;
							padding: 			8px;
							background: 		#e6e6e6;
							border-radius: 		5px;
							color: 				#566481;
							// opacity: 	0;
							transition:	.2s ease-in;
						}
					}
					&.value {
						color:			#1eb1fa;
					}
					&.date {
						max-width:		110px;
					}
					&.profile {
						width:			200px;
					}
					&.number {
						width:			100px;
					}
					&.action {
						text-align:		right;
						padding-right:	1rem;
						& > span {
							&.link {
								color:			rgba(66, 165, 245, 0.6);
								font-weight:	600;
								padding:		0 5px;
								transition:		color ease-out 0.3s;
								&:hover {
									color:			rgba(66, 165, 245, 1);
								}
							}
						}
					}
					.ago {
						font-size:		10px;
						color:			rgba(0,0,0,0.6);
						line-height:	15px;
						font-weight:	500;
					}
					&.toggle {
						width:			60px;
						text-align:		center;
						.active {
							color:	rgba(156, 204, 101, 1);
						}
						.inactive {
							color:	rgba(229, 115, 115, 1);
						}
					}
				}
				&:hover {
					background-color:	rgba(66, 165, 245, 0.1);
					.edit {
						span {
							// opacity:		1;
						}
					}
				}
				&.none {
					&:hover {
						background:	#ffffff;
					}
				}
				&.selected {
					background-color:	rgba(66, 165, 245, 0.2);
					& > td {
						color:				#ffffff;
					}
				}
			}
		}
	}
}

.target-locator {
	border:		1px solid rgba(0,0,0,0.2);
	width:		500px;
	margin:		0 auto;
	& .search {
		position:		relative;
		border-bottom:	1px solid rgba(0,0,0,0.2);
		& > input {
			width:			100% !important;
			min-width:		100%;
			max-width:		100%;
			box-sizing:		border-box;
			border:			0;
		}
		& > .loading {
			position:	absolute;
			top:		3px;
			right:		3px;
			font-size:	20px;
		}
		& > .dropdown {
			position:	relative;
			& > div {
				position:	absolute;
				width:		100%;
				top:		0;
				left:		0;
				z-index:	50000;
				background-color:	rgba(255,255,255,0.8);
				max-height:	250px;
				overflow-y:	auto;
				
				
				/*
				<div class="block">
					<div class="name">Countries</div>
					<table>
						<tbody>
							<tr ng-repeat="item in main.suggestions.countries">
								<td class="country">
									{{item.country_name}}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				*/
				
				.block {
					padding:		0;
					margin:			0;
					& > .name {
						background-color:	rgba(226, 231, 237, 1);
						color:				#333333;
						font-weight:		700;
						font-size:			13px;
						padding:			5px 10px;
					}
					
					& > .list {
						& > div {
							padding:			5px;
							background-color:	rgba(33, 150, 243, 0);
							transition:			background-color ease-out 0.3s;
							&:hover {
								background-color:	rgba(33, 150, 243, 0.3);
							}
							& > div {
								display:		inline-block;
								color:			#333333;
								font-weight:	500;
								font-size:		11px;
								padding:		5px;
								&.country {
									
								}
								&.country_code {
									
								}
								&.city {
									
								}
								&.main {
									font-weight:	900;
								}
							}
						}
					}
				}
			}
		}
	}
	& > .map {
		height:	300px;
	}
}

.ui-location-selector {
		display:				table;
		border:					1px solid rgba(0,0,0,0.2);
		background-color:		rgba(226, 231, 237, 1);
		border-radius:			5px;
		box-shadow: 			0 1px 2px 0 rgba(0, 0, 0, 0.16);
		&.disabled {
			opacity: 0.5;
		}
		& > div {
			border-radius:		5px;
			display:			table-row;
			
			& > div {
				display:			table-cell;
				vertical-align:		middle;
				padding:			10px;
				color:				#333333;
				background-color:	rgba(33, 150, 243, 0);
				transition:			background-color ease-out 0.3s;
				cursor:				default;
				text-align:			center;
				&.remove {
					cursor:				pointer;
					transition:			background-color ease-out 0.3s;
					background-color:	rgba(244, 67, 54, 0);
					&:hover {
						background-color:	rgba(244, 67, 54, 0.5);
					}
				}
				
				.main {
					font-size:		12px;
					font-weight:	600;
				}
				.secondary {
					font-size:		11px;
					font-weight:	400;
					padding:		0 5px;
				}
				.tertiary {
					font-size:		10px;
					font-weight:	300;
				}
				&.active {
					background-color:	rgba(33, 150, 243, 1);
					color:				#ffffff;
				}
				&:first-child {
					border-radius:		5px 0 0 5px;
				}
				&:last-child {
					border-radius:		0 5px 5px 0;
				}
			}
			
			&:hover {
				& > div {
					background-color:	rgba(33, 150, 243, 0.3);
				}
			}
		}
}

// campaign details 
.details-wrapper {
	max-height: 	600px;
    overflow-y: 	auto;
    overflow-x: 	hidden;
	font-family:	'Lato', sans-serif;
	.heading {
		font-size:		1rem;
		font-weight:	600;
		margin-bottom:	1rem;
		padding-bottom: .5rem;
    	border-bottom: 	1px solid #dbdbdb;
	}
	.content-wrapper {
		padding:		1rem 1rem 0 1rem;
		section {
			display: 		flex;
			flex-direction: column;
			&:not(:last-child) {
				margin-bottom:	1rem;
			}
			&.subsection {
				&:not(:last-child) {
					margin-bottom:	.5rem;
					padding:		0 1rem 1rem 1rem;
				}
				& > div {
					font-weight:	600;
				}
			}
			.location-wrapper {
				display:	flex;
				flex-wrap:	wrap;
				.location {
					margin-right:	2px;
					padding:		4px 8px;
					background:		#e6e6e6;
					color:			#202020;
					border-radius:	20px;
				}
			}
			.gender-wrapper {
				display:	flex;
				flex-wrap:	wrap;
				.gender {
					margin-right:	8px;
					padding:		4px 8px;
					background:		#e6e6e6;
					color:			#202020;
					border-radius:	20px;
				}
			}
			label {
				display: 		block;
				margin-bottom: .25rem;
				color:			#e6eaea;
				font-size: 		.75rem;
				font-family: 	'Lato', sans-serif;
				font-weight: 	600;
				letter-spacing:	.3px;
				line-height: 	1;
			}
			.text {
				font-weight:	600;
			}
			input {
				padding:		.5rem;
				color:			#000000;
				font-weight:	600;
				border:			2px solid #c6c6c6;
				border-radius:	3px;
			}
			textarea {
				padding: 		7px;
				color:			#000000;
				font-size:		16px;
				font-weight:	600;
				border: 		1px solid #c6c6c6;
				border-radius: 	3px;
				resize: 		none;
				&::placeholder {
					font-size:	16px;
					color:		#e6eaea;
				}
			}
			.merchant-description {
				max-height:		300px;
				color:			#000000;
				font-size:		16px;
				font-weight:	600;
				white-space: 	nowrap;
				overflow: 		hidden;
				text-overflow: 	ellipsis;	
			}
			.image-wrapper {
				display:		flex;
				flex-wrap: 		wrap;
				padding:		.5rem;
				border:			1px solid #e6eaea;
				&:before {
					display: table;
					content: "";
					line-height: 0;
					font-size: 0;
				}
				img {
					width: 					49%;
					height: 				auto;
					padding: 				0;
					background-size: 		cover;
					background-repeat: 		no-repeat;
					background-position: 	center center;
					margin: 				2px;
					@media screen and (max-width: 480px) {
						width:				100%;
					}
				}
			}
			.offers-wrapper {
				display:			flex;
				flex-wrap:			wrap;
				.offer {
					margin-right:	8px;
					padding: 		4px 8px;
					background:		#e6e6e6;
					color:			#202020;
					font-weight:	600;
					border-radius:	20px;
				}
			}
		}
	}
	.ui-nav {
		&.ctas {
			@media screen and (max-width: 480px) {
				display:		flex;
				flex-direction:	column;
				& > a,
				& > div {
					margin:		0;
				}
			}
		}
	}
}