.ellipsis {
	width:			150px;
	white-space:	nowrap;
	overflow:		hidden;
	text-overflow:	ellipsis;
}

.text-error {
	color:			#e70028;
	font-size:		12px;
}
	
input {
	border-radius:	3px;
	box-shadow:		0 2px 4px rgba(0, 0, 0, 0.1);
	border:			1px solid #B9B9B9;
	&.error {
		border:		1px solid rgba(198, 40, 40, 0.6) !important;
		box-shadow:	0 2px 7px rgba(198, 40, 40, 0.4) !important;
	}
}

.corner-loading {
	position:		fixed;
	bottom:			0;
	right:			0;
	padding:		5px 15px;
	font-size:		12px;
	font-weight:	600;
	color:			#ffffff;
	background-color:	rgba(0,0,0, 0.3);
	border-radius:	3px 0 0 0;
}



.fixed-floating-bottom {
	position:			absolute;
	bottom:				0;
	width:				100%;
	height:				2px;
	z-index:			5000;
	text-align:			center;
	& > div {
		position:	relative;
		display:	inline-block;
		transform:	translateY(-100%);
	}
}


/* hide callback logs container for mobile devices */
@media (max-width: 768px) {
	.callback-logs-wrapper {
		display:	none;
	}
}

.review-queue {
	width:				100%;
	overflow:			auto;
	background-color:	#FCFCFC;
	padding:			20px;
	& > div {
		padding:		0 10px;
	}
	& > table {
		margin:			10px auto;
		box-shadow:		0 3px 6px rgba(0,0,0,0.5);
		border-radius:	3px 3px 0 0;
		& > thead {
			& > tr {
				& > th {
					font-size:			16px;
					font-weight:		700;
					color:				#ffffff;
					padding:			10px;
					text-align:			center;
					background-color:	#599CCC;
					&:first-child {
						border-radius:	3px 0 0 0;
					}
					&:last-child {
						border-radius:	0 3px 0 0;
					}
				}
			}
		}
		& > tbody {
			& > tr {
				& > td {
					font-size:		14px;
					font-weight:	500;
					color:			rgba(0,0,0,0.8);
					padding:		10px;
					text-align:		center;
					&.right {
						text-align:	right;
					}
					&.left {
						text-align:	left;
					}
				}
				background-color:	rgba(255, 255, 255, 1);
				transition:		background-color ease-out 0.3s, color ease-out 0.3s;
				&:hover {
					background-color:	rgba(66, 165, 245, 0.1);
				}
				
				.action {
					padding:				2px 7px;
					background-color:		rgba(255, 255, 255, 0);
					transition:				background-color ease-out 0.3s, color ease-out 0.3s;
					border-radius:			3px;
					&:hover {
						background-color:	rgba(66, 165, 245, 0.2);
						color:				#599CCC;
					}
				}
			}
		}
	}
}


.server-status {
	background-color:	rgba(255,255,255, 1);
	padding:			20px;
	& > table {
		margin:		0 auto;
		& > thead {
			& > tr {
				& > th {
					padding:	5px;
				}
			}
		}
		& > tbody {
			& > tr {
				& > td {
					padding:	5px;
					text-align:	center;
					background-color:	rgba(0,0,0, 0.1);
					vertical-align:	middle;
					&.up {
						background-color:	rgba(174, 213, 129, 1);
						color:				rgba(255,255,255,1);
					}
					&.down {
						background-color:	rgba(239, 154, 154, 1);
						color:				rgba(255,255,255,1);
					}
					&.name {
						background-color:	transparent;
						color:				#333333;
						text-align:			right;
						font-weight:		700;
						font-size:			13px;
					}
					.last-contact {
						font-size:		10px;
					}
				}
			}
		}
	}
}




[ng-click] {
	cursor:	pointer;
}

a {
	text-decoration:	none;
	color:				#42A5F5;
	&:hover {
		text-decoration:	underline;
	}
};

code {
	background-color:	rgba(120, 144, 156, 0.9);
	border-radius:		2px;
	padding:			2px 3px;
	color:				#ffffff;
	font-size:			11px;
	font-weight:		300;
}

input[readonly] {
	background-color:	rgba(226, 231, 237, 0.9) !important;
	border:				0;
	border-radius:		3px;
}


.ace_editor {
	height:	300px;
	
	&.xs {
		height:	50px;
	}
	&.small {
		height:	150px;
	}
	&.xm {
		height:	310px;
	}
	&.medium {
		height:	500px;
	}
	&.large {
		height:	700px;
	}
}

.ui-tools {
	position:				absolute;
	top:					0;
	left:					0;
	width:					100%;
	height:					100%;
	overflow-y:				hidden;
	font-family: 			'Roboto', sans-serif;
	background: 			#F7FCFF;
	background-image:		url('/swish.svg');
	background-position:	top center;
	background-size:		100% auto;
	background-repeat:		no-repeat;
	/*background: linear-gradient(180deg, rgba(225,228,234,1) 0%, rgba(227,234,240,1) 100%);*/
	
	& > .ui-header {
		background-color:	rgba(55, 62, 92, 1);
		color:				#ffffff;
		font-weight:		700;
		font-size:			15px;
		text-align:			center;
		line-height:		45px;
		border-bottom:		1px solid rgba(0,0,0,0.4);
		& > div.navbar-container {
			box-sizing:			border-box;
			width:				100%;
			display:			flex;
			justify-content:	space-between;
			padding:			0 16px;
			height:				60px;
			align-items:		center;
			@media screen and (max-width: 480px) {
				padding: 		0;
			}
			& > div.logo {
				display:		flex;
				height:			100%;
				padding:		0 16px;
				line-height:	unset;
				a {
					display:			flex;
					align-items:		center;
					background-color:	transparent;
					img {
						max-width:		100%;
					    margin: 		0;
					    padding: 		0;
					    border-style: 	none;
					}
				}
			}
			& > div.nav-links {
				height:				100%;
				display:			flex;
				justify-content:	space-between;
				padding:			0 16px;
				align-items:		center;
				@media screen and (max-width: 480px) {
					padding:		0;
				}
				& > div.menu {
					text-align:		right;
					padding: 		0 8px;
					@media (max-width: 1200px) {
						line-height: 30px;
					}
					.menu-desktop {
						& > div, & > a {
							display:			inline-block;
							vertical-align:		top;
							padding:			2px 10px;
							font-size:			15px;
							font-weight:		600;
							color:				#ffffff;
							text-decoration:	none;
							transition:			color ease-out 0.3s;
							&:hover {
								color:				rgba(66, 165, 245, 1);
								cursor:				pointer;
								text-decoration:	none;
							}
						}
						@media screen and (max-width: 992px) {
							display:	none;
						}
						.button-style {
							padding:		0 8px !important;
							// background:		#27ae60 !important;
							background:		#56c135 !important;
							color:			#ffffff !important;
							border-radius:	3px;
							vertical-align:	middle !important;
							line-height:	30px !important;
							transition:		all 0.3s ease-out;
							&:hover {
								// background:	#1f8b4c !important;
								background:	#49a02f !important;
							}
						}
					}
					.menu-mobile {
						display:	none;
						@media screen and (max-width: 992px) {
							display:	block;
						}
					}
				}
				
				& > div.sep {
					width:			5px;
					text-align:		center;
					margin:			0 7px;
					& > div {
						height:				20px;
						width:				1px;
						background-color:	rgba(0,0,0, 0.6);
						display:			inline-block;
						vertical-align:		middle;
					}
				}
								
								
				& > div.account {
					text-align:		center;
					padding:		0 8px;
					@media screen and (max-width: 480px) {
						padding:	0;
					}
					& > .avatar {
						width:				30px;
						height:				30px;
						background-color:	rgba(0,0,0, 0.5);
						border-radius:		50%;
						display:			inline-block;
						vertical-align:		middle;
					}
					svg {
						margin-right:		7px;
					}
				}
								
								
				& > div.notifications {
					width:		40px;
					text-align:	center;
					font-size:	28px;
				}
				
			}
		}
	}
					
					
	& > .ui-content {
		overflow-y:	auto;
		color:		#333333;
		position:	relative;
	}
}

.hover-menu {
	line-height:			60px;
	transition:				0.3s ease-out;
	border-top-color: 		transparent;
	@media screen and (max-width: 768px) {
		line-height:		18px;
	}
	& > div:first-child {
	    /*display: 		flex;*/
	    height: 		100%;
	    width:			100%;
		align-items:	center;
		@media screen and (max-width: 480px) {
			font-size:	13px;
		}
		@media (max-width: 1200px) {
			line-height:	30px;
		}
	    .tag {
	    	font-size: 		10px;
    		margin-left: 	7px;
			padding:		2px 6px;
			@media screen and (max-width: 480px) {
				font-size:	11px;
			}
	    }	
	}
	& > .hover-menu-list {
		display:	none;
		width:		100%;
		position:	relative;
		& > .list {
			position:						absolute;
			top:							-1px;
			right:							-9px;
			width:							170px;
			z-index:						50000;
			background-color:				#ffffff;
			box-shadow:						1px 2px 3px 0 rgba(0, 0, 0, 0.66);
			max-height:						300px;
			overflow-y:						auto; /*auto, not scroll. Otherwise the scrollbar is always visible*/ 
			overflow-x:						hidden;
			border-top:						0;
			border-bottom-right-radius:		3px;
			border-bottom-left-radius:		3px;
			& > div, & > a {
				width:				150px;
				white-space:		nowrap;
				overflow:			hidden;
				text-overflow:		ellipsis;
				display:			block;
				text-decoration:	none;
				padding:			10px;
				font-size:			12px;
				font-weight:		400;
				text-align:			left;
				transition:			background-color ease-out 0.3s;
				line-height:		20px;
				color: 				#fff;
    			background-color: 	#373e5c;
    			/*border-bottom:		1px solid #e7e9eb;*/
    			&:last-child {
    				border-bottom: 	0;
    			}
				&:hover {
					background-color: 		#525c86;
				}
				&.selected {
					background-color: 		lighten(#525c86, 5%);
				}
			}
			&.light {
				& > div, & > a {
					background-color: 	transparent;
					color:				#333333;
					&:hover {
						background-color: 		rgba(3, 155, 229, 0.4);
					}
					&.selected {
						background-color: 		rgba(3, 155, 229, 0.5);
					}
					&.sep {
						padding:		2px;
						border-bottom:	1px dotted rgba(0, 0, 0, 0.3);
						margin:			0 auto;
						margin-bottom:	5px;
						&:hover {
							background-color: 	transparent;
						}
					}
				}
			}
		}
	}
	/*
		hover-menu should control hover-menus and nothing else.
		Create a separate class for this kind of custom style that only apply in the header bar.
		Otherwise, all hover-menus accross the site get that style. I use hover menus in various management UIs.
	*/
	/*&:hover {
		background: 			rgba(255,255,255,0.1);
		cursor:					pointer;
		border-top-color:		#42a5f5;
		.hover-menu-list {
			display:	block;
		}
	}*/
	&:hover {
		.hover-menu-list {
			display:	block;
		}
		.hover-menu-list.hide {
			display:	none;
		}
	}
}


.ng-highlight {
	/*background-color:	rgba(255, 204, 128, 0.3);*/
	color:	rgba(216, 27, 96, 1);
}




.account-dashboard {
	margin:				20px auto;
	max-width:			300px;
	& > .title {
		font-size:		14px;
		font-weight:	700;
		color:			#333333;
		text-align:		center;
		margin:			10px;
	}
	& > .subtitle {
		font-size:		11px;
		font-weight:	500;
		color:			rgba(0,0,0,0.6);
		text-align:		center;
		margin:			0 10px 20px;
	}
	& > .list {
		display:			table;
		width:				100%;
		margin:				0 auto;
		border-radius:		5px;
		background-color:	rgba(255,255,255,0.8);
		box-shadow:			0 2px 6px rgba(0,0,0,0.1);
		& > div, & > a {
			display:			table-row;
			background-color:	rgba(66, 165, 245, 0);
			transition:			background-color ease-out 0.3s;
			&:hover {
				background-color:	rgba(66, 165, 245, 1);
			}
			& > div {
				display:		table-cell;
				padding:		10px;
				vertical-align:	middle;
				text-align:		left;
				
				.name {
					font-size:		13px;
					font-weight:	900;
					color:			#333333;
				}
				.about {
					font-size:		11px;
					font-weight:	400;
					color:			rgba(0,0,0,0.5);
				}
				&:last-child {
					border-radius:	5px;
				}
			}
		}
	}
}


/*
<div class="page-col">
	<div>
		<div class="col">
			<div class="col-block">
				<div class="name">
					Merchant Overview
				</div>
				<div class="list">
					<div>Conversion V. Impressions</div>
					<div>Dollars Viewed V. Cashed out</div>
				</div>
			</div>
			
			<div class="col-block">
				<div class="name">
					Campaign Analytics
				</div>
				<div class="list">
					<div>Active Campaigns</div>
					<div>Archived Campaigns</div>
				</div>
			</div>
		</div>
		<div class="main">
			
		</div>
	</div>
</div>
*/

.page-col {
	display:	table;
	position:	absolute;
	top:		0;
	left:		0;
	width:		100%;
	height:		100%;
	
	& > div {
		display:	table-row;
		& > div {
			display:	table-cell;
			&.col {
				width:	250px;
				background-color:	rgba(251, 254, 255, 1);
				
				.col-block {
					padding:	30px;
					border-bottom:	1px solid rgba(0,0,0,0.1);
					& > .name {
						font-size:		14px;
						font-weight:	900;
						color:			#000000;
						padding:		10px 0;
					}
					& > .list {
						padding-left:	5px;
						& > div {
							font-size:		12px;
							font-weight:	500;
							color:			#333333;
							padding:		3px 5px;
						}
					}
				}
			}
			&.main {
				background-color:	rgba(226, 232, 238, 1);
				box-shadow:			inset 5px 0 10px rgba(0,0,0,0.5);
				
				& > div {
					padding:	30px;
				}
			}
		}
	}
}

.chart-widgets {
	display:		flex;
	flex-wrap:		wrap;
    align-items:	stretch;
    
    & > div {
		flex:		25%;
		margin:		10px;
		padding:	5px;
		border:		1px solid #ccc;
		box-shadow:	2px 2px 6px 0px  rgba(0,0,0,0.3);
		background-color:	rgba(255,255,255, 1);
		border-radius:	5px;
		&.w50 {
			flex:	50%;
		}
		&.w75 {
			flex:	75%;
		}
		&.w100 {
			flex:	100%;
		}
		& > .title {
			font-size:		14px;
			font-weight:	900;
			color:			#333333;
			padding:		10px;
		}
    }
}

.stats-widgets {
	display:		flex;
	flex-wrap:		wrap;
    align-items:	stretch;
    
    & > div {
		flex:		25%;
		margin:		10px;
		padding:	5px;
		border:		1px solid #ccc;
		box-shadow:	2px 2px 6px 0px  rgba(0,0,0,0.3);
		background-color:	rgba(255,255,255, 1);
		border-radius:	5px;
    }
}

.list-widget {
	overflow-y:		auto;
	max-height:		350px;
	& > table {
		width:		100%;
		& > thead {
			& > tr {
				& > th {
					font-size:		10px;
					font-weight:	900;
					color:			#333333;
					padding:		2px;
					text-align:		left;
				}
			}
		}
		& > tbody {
			& > tr {
				& > td {
					font-size:		10px;
					font-weight:	500;
					color:			rgba(0,0,0,0.6);
					padding:		2px;
					&.right {
						text-align:	right;
					}
				}
				cursor:				default;
				background-color:	rgba(66, 165, 245, 0);
				transition:		background-color ease-out 0.3s, color ease-out 0.3s;
				&:hover {
					background-color:	rgba(66, 165, 245, 0.1);
				}
			}
		}
	}
}

.stats-widget {
	& > .title {
		font-size:		16px;
		font-weight:	900;
		color:			rgba(0,0,0,0.6);
		padding:		10px 0;
	}
	& > .value {
		font-size:		24px;
		font-weight:	900;
		color:			rgba(0,0,0,0.9);
	}
	& > .change {
		font-size:		12px;
		font-weight:	900;
		color:			rgba(0,0,0,0.7);
	}
}



/*
	Date-range picker
	https://www.daterangepicker.com/
*/
.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
}

.daterangepicker:before, .daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar {
  display: block;
}

.daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
  padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}

.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff;
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}

.daterangepicker .drp-buttons .daterange-btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}

.daterangepicker .ranges li:hover {
  background-color: #eee;
}

.daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    width: 140px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges, .daterangepicker.single .drp-calendar {
    float: left;
  }

  .daterangepicker {
    direction: ltr;
    text-align: left;
  }

  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }

  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }

  .daterangepicker .ranges, .daterangepicker .drp-calendar {
    float: left;
  }
}

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }

  .daterangepicker .ranges {
    float: left;
  }

  .daterangepicker.rtl .ranges {
    float: right;
  }

  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}