.publishers {
	
	.page-container {
		padding:	0 25px;
	}
	
	
}

.page-banner {
	padding:			50px;
//  box-shadow:			0 1px 16px rgba(0,0,0,0.2);
	margin-bottom:		25px;
	background-color:	#ffffff;
	background-image:	url('/bg-banner.svg');
	background-repeat:	no-repeat;
	background-size:	cover;
	.title {
		font-size:		30px;
		font-weight:	700;
		color:			rgba(86, 100, 129, 1);
		line-height:	40px;
	}
	.subtitle {
		font-size:		20px;
		font-weight:	400;
		color:			rgba(86, 100, 129, 1);
		line-height:	25px;
		letter-spacing:	1px;
		margin-top:		15px;
	}
	.cta {
		margin-top:			20px;
		cursor:				pointer;
		display:			inline-block;
		vertical-align:		middle;
		text-decoration:	none;
		border-radius:		5px;
		padding:			0 25px;
		background-color:	rgba(66, 165, 245, 1);
		color:				#ffffff;
		font-size:			14px;
		line-height:		45px;
		font-weight:		600;
		box-shadow:			1px 1px 5px rgba(0, 0, 0, 0.2);
		transition:			transform 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
		position:			relative;
		
		&:hover {
			background-color:	darken(rgba(66, 165, 245, 1), 10%);
			text-decoration:	none;
		}
	}
	@media screen and (max-width: 480px) {
		padding:		0 16px;
	}
	@media screen and (max-width: 992px) {
		padding:	20px;
		.title {
			font-size:		20px;
			line-height:	30px;
		}
		.subtitle {
			font-size:		14px;
			line-height:	auto;
		}
	}
}

.profile-title {
	text-align:		center;
	font-size:		16px;
	font-weight:	700;
	color:			rgba(147, 206, 146, 0.6);
}

.profile-list {
	& > div {
		display:		table;
		margin:			0 auto;
		border-radius:	5px;
		border:			1px solid rgba(0,0,0,0.2);
		& > div {
			display:	table-row;
			background-color:	rgba(66, 165, 245, 0);
			transition:		background-color ease-out 0.3s;
			&:hover {
				background-color:	rgba(66, 165, 245, 1);
			}
			& > div {
				display:		table-cell;
				padding:		5px;
				vertical-align:	middle;
				text-align:		left;
				
				.name {
					font-size:		13px;
					font-weight:	900;
					color:			#333333;
				}
				.about {
					font-size:		11px;
					font-weight:	400;
					color:			rgba(0,0,0,0.5);
				}
				&:first-child {
					border-radius:	5px 0 0 5px;
					width:	60px;
					img {
						width:			50px;
						height:			50px;
						border-radius:	50%;
					}
				}
				&:last-child {
					border-radius:	0 5px 5px 0;
				}
			}
		}
	}
	.app-dialog-box & {
		margin:	20px;
	}
	.ui-form & {
		& > div {
			margin:	10px;
		}
	}
	&.center-box {
		margin:			0 auto 20px;
	}
}