tr.empty > td {
	width:			100%;
	padding:		1.5rem 0 !important;
	text-align:		center !important;
	font-size:		1rem !important;
	font-weight:	600 !important;
	pointer-events:	none !important;
}

.btn {
	box-sizing:			border-box;
	cursor:				pointer;
	display:			inline-block;
	vertical-align:		middle;
	text-decoration:	none;
	text-align:			center;
	border-radius:		5px;
	background-color:	rgba(66, 165, 245, 1);
	color:				#ffffff;
	font-weight:		600;
	box-shadow:			1px 1px 5px rgba(0, 0, 0, 0.2);
	transition:			transform 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
	font-size:			12px;
	line-height:		35px;
	padding:			0 15px;
	margin:				5px;
	&:hover {
		background-color:	darken(rgba(66, 165, 245, 1), 10%);
		text-decoration:	none;
	}
	&.full-width {
		width:	100%;
	}
}

.split-view {
	@media screen and (max-width: 1500px) {
		position:	relative;
		& > div {
			
			& > div {
				position:	absolute;
				height:		50%;
				width:		100%;
				top:		0;
				left:		0;
				overflow-y:	auto;
				overflow-x:	hidden;
				&:first-child {
					padding:	0;
				}
				&:last-child {
					top:		50%;
					border-top:	2px solid #333;
				}
			}
		}
	}
	@media screen and (min-width: 1500px) {
		position:	relative;
		& > div {
			& > div {
				position:	absolute;
				height:		100%;
				top:		0;
				left:		0;
				overflow-y:	auto;
				overflow-x:	hidden;
				&:first-child {
					width:		50%;
					padding:	0;
				}
				&:last-child {
					width:		50%;
					left:		50%;
					border-left:2px solid #333;
				}
			}
		}
	}
}


.giftcard-display {
	position:			relative;
	margin:				0 auto;
	width:				300px;
	height:				190px;
	border-radius:		10px;
	background-size:	cover;
	background-repeat:	no-repeat;
	background-position:center center;
	box-shadow:			2px 2px 20px rgba(0, 0, 0, 0.1);
	&.small {
		width:			64px;
		height:			40px;
		border-radius:	4px;
	}
}

.giftcard-details {
	& > tbody {
		& > tr {
			& > td {
				@media (max-width: 768px) {
					.giftcard-display {
						display:	none;
					}
				}
				padding:	10px;
				&:first-child {
					vertical-align:	middle;
				}
				&:last-child {
					vertical-align:	top;
					.name {
						font-size:		14px;
						font-weight:	600;
						margin-bottom:	10px;
					}				
				}
			}
		}
	}
}


.ticket-comments {
	.ticket-comment-box {
		border-radius:		10px;
		box-shadow:			2px 2px 20px rgba(0, 0, 0, 0.1);
		margin:				20px 5px 10px;
		font-size:			14px;
		padding:			10px 20px;
	}
	.comment-date {
		margin:				0 10px 30px;
		font-weight:		400;
		font-size:			12px;
		text-align:			left;
	}
}

.form {
	display:	table;
	& > div {
		display:	table-row;
		& > div {
			display:	table-cell;
			padding:	3px 5px;
			&:first-child {
				font-size:		12px;
				font-weight:	400;
				color:			rgba(0,0,0,0.6);
			}
			&:last-child {
				font-size:		12px;
				font-weight:	600;
				color:			rgba(0,0,0,0.9);
			}
		}
	}
}

.support-ui {
	background-color:	#ffffff;
	font-family:		"Lato", sans-serif;
	& > .title {
		font-size:		16px;
		font-weight:	600;
		letter-spacing:	1px;
		color:			#333333;
		padding:		5px;
	}

	.horizontal-scroll-except-first-few-columns {
		width: 		100%;
		overflow:	auto;
	}

	table.acc-list {
		width:		100%;
		padding:	0 10px;
		& > thead {
			text-align:	right;
			& > tr {
				& > th {
					padding:		5px;
					padding-top:	17px;
					position:		sticky;
					top:			0;
					font-size:		17px;
					font-weight:	900;
					border-top:	1px solid #dcdddf;
					border-bottom:	1px solid #dcdddf;
					text-transform:	uppercase;
					z-index:		2;
					position:		sticky;
					top:			0;
					&:first-child {
						position:		sticky;
						left:			0;
						padding-right: 	17px;
						background:		#ffffff;
						z-index:		3;
					}
					&:last-child {
						border-right-width: 0;
					}
					@media (max-width: 480px) {
						font-size:		14px;
					}
				}
			}
		}
		& > tbody {
			& > tr {
				cursor:		pointer;
				text-align:	right;
				& > th {
					padding-right:	5px;
				}
				& > td {
					vertical-align:	middle;
					cursor:			default;
					padding:		5px;
					font-size:		13px;
					font-weight:	600;
					color:			rgba(0,0,0,0.7);
					text-align:		right;
					border-right:	1px solid #dcdddf;
					border-bottom:	1px solid #dcdddf;
					@media (max-width: 768px) {
						&:first-child {
							position:		sticky;
							left:			0;
							background:		#ffffff;
							box-shadow:		2px 0px 2px rgba(0, 0, 0, 0.3);
							z-index:		2;
						}
					}
					&:last-child {
						border-right-width: 0;
					}
					.small {
						font-size:	10px;
					}
					&.radio {
						width:			50px;
						font-size:		20px;
						text-align:		center;
					}
					&.name {
						max-width:		150px;
						font-weight:	600;
					}
					&.user {
						width:			100px;
						max-width: 		100px;
						padding-right:	17px;
						a {
							text-decoration: none;
						}
						@media (max-width: 480px) {
							width:		120px;
						}
						white-space:	nowrap;
						overflow:		hidden;
						text-overflow:	ellipsis;
						text-align:		right;
						a {
							display:		block;
							// width:			180px;
							white-space:	nowrap;
							overflow:		hidden;
							text-overflow:	ellipsis;
						}
					}
					&.date {
						width:			100px;
						min-width:		100px;
						font-weight:	500;
						&.large {
							width:		150px;
						}
					}
					&.status {
						width:			50px;
						text-align:		right;
					}
					&.shortcode {
						max-width:		40px;;
					}
					&.giftcard {
						width:			76px;
						text-align:		center;
					}
					&.profile {
						width:			200px;
					}
					&.value {
						width:			100px;
						text-align:		right;
					}
					&.card {
						width:			120px;
						min-width:		120px;
						@media (max-width: 1000px) {
							width:		120px;
						}
					}
					&.right {
						text-align:		right;
					}
					&.action {
						text-align:		center;
						width:			110px;
						min-width:		110px;
						& > span {
							&.link {
								color:			rgba(66, 165, 245, 0.6);
								font-weight:	600;
								padding:		0 5px;
								transition:		color ease-out 0.3s;
								&:hover {
									color:			rgba(66, 165, 245, 1);
								}
							}
						}
					}
					.ago {
						font-size:		10px;
						color:			rgba(0,0,0,0.6);
						line-height:	15px;
						font-weight:	500;
					}
				}
				background-color:	rgba(33, 150, 243, 0);
				transition:			background-color ease-out 0.3s;
				&:hover {
					// background-color:	rgba(33, 150, 243, 0.3);
					background-color:	#42a5f5;
					& > td {
						color:		#ffffff !important;
						background:	#42a5f5 !important;
						& a {
							color:			#ffffff;
						}
					}
					.tag {
						background:	#ffffff;
						color:		#42a5f5;
						&.red {
							background:		#d32f2f;
							color:			#ffffff;
						}
						&.green {
							background:		#66bb6a;
							color:			#ffffff;
						}
						&.orange {
							background:		#ff9800;
							color:			#ffffff;
						}
					}
				}
				&.even {
					background-color:	rgba(0,0,0,.02);
				}
				
				&.selected {
					// background-color:	rgba(66, 165, 245, 0.7);
					background:			#42a5f5;
					& > td {
						color:				#ffffff;
						&:first-child {
							// background:		rgba(66, 165, 245, 0.7);
							background:			#42a5f5;
							color:				#ffffff;
						}
						a {
							color:			#ffffff;
						}
					}
					/*
					.tag {
						background:	#ffffff;
						color:		#42a5f5;
						&.red {
							background:		#d32f2f;
							color:			#ffffff;
						}
						&.green {
							background:		#66bb6a;
							color:			#ffffff;
						}
						&.orange {
							background:		#ff9800;
							color:			#ffffff;
						}
					}
					*/
				}
			}
		}
	}
	
	table.email-list {
		width:	100%;
		padding:	0 10px;
		& > thead {
			& > tr {
				& > th {
					padding:		5px;
					padding-top:	17px;
					font-size:		17px;
					font-weight:	900;
					text-align:		right;
					border-top:		1px solid #dcdddf;
					border-bottom:	1px solid #dcdddf;
					&:first-child {
						position:		sticky;
						left:			0;
						padding-right:	17px;
						background:		#ffffff;
						z-index:		3;
					}
					&:last-child {
						border-right-width: 0;
					}
					@media (max-width: 480px) {
						font-size:		14px;
					}
				}
			}
		}
		& > tbody {
			& > tr {
				cursor:	pointer;
				& > th {
					padding-right:	5px;
				}
				& > td {
					vertical-align:	middle;
					cursor:			default;
					padding:		5px;
					font-size:		13px;
					font-weight:	600;
					color:			rgba(0,0,0,0.7);
					text-align:		right;
					border-right:	1px solid #dcdddf;
					border-bottom:	1px solid #dcdddf;
					@media (max-width: 768px) {
						&:first-child {
							position:		sticky;
							left:			0;
							background:		#ffffff;
							box-shadow:		2px 0px 2px rgba(0, 0, 0, 0.3);
							z-index:		3;
						}
					}
					&:last-child {
						border-right-width: 0;
					}
					.small {
						font-size:	10px;
						@media (max-width: 1000px) {
							padding:	0 5px;
						}
					}
					&.message-id {
						padding-right:	17px;
						font-weight:	600;
					}
					&.email {
						min-width:		120px;
						font-weight:	600;
					}
					&.date {
						max-width:		150px;
						min-width:		100px;
						font-weight:	400;
					}
					&.endpoint {
						min-width:		150px;
					}
					&.events {
						min-width:		180px;
					}
					&.small {
						font-size:		11px;
						@media (max-width: 1000px) {
							padding:	0 5px;
						}
					}
					&.right {
						text-align:		right;
					}
					&.center {
						text-align:		center;
					}
					&.empty {
						width:			100%;
						text-align:		center;
						padding:		1.5rem;
						font-size:		1rem;
						font-weight:	600;
						pointer-events:	none;
					}
					&.action {
						min-width:		120px;
						text-align:		center;
						& > span {
							&.link {
								color:			rgba(66, 165, 245, 0.6);
								font-weight:	600;
								padding:		0 5px;
								transition:		color ease-out 0.3s;
								&:hover {
									color:			rgba(66, 165, 245, 1);
								}
							}
						}
					}
				}
				background-color:	rgba(33, 150, 243, 0);
				transition:			background-color ease-out 0.3s;
				&:hover{
					td, .message-id {
						color:			#ffffff !important;
						background:		#42a5f5 !important;
					}
				}
				&.even {
					background-color:	rgba(144, 164, 174, 0.2);
				}
				&.selected {
					background-color:	rgba(66, 165, 245, 0.7);
					& > td {
						color:				#ffffff;
						a {
							color:			#ffffff;
						}
					}
				}
			}
		}
	}
	.ui-nav {
		&.ctas {
			display:	flex;

			& > div {
				width:		125px;
				margin:		5px;
				padding:	0 5px;
				font-size:	12px;
				&:not(:last-child) {
					margin-right:	16px;
				}
			}
		}
	}
	&.autosize {
		& > table {
			width:	auto;
			margin:	0 auto;
		}
	}
	&.center {
		& > table {
			th {
				text-align:	center;
			}
			td {
				text-align:	center;
			}
		}
	}
	&.border {
		& > table {
			td {
				border-right:	1px solid rgba(0,0,0,0.1);
				border-bottom:	1px solid rgba(0,0,0,0.1);
				&:last-child {
					border-right:	0;
				}
			}
		}
	}
}


.history-desktop {
	display:	none !important;
	@media screen and (min-width: 481px) {
		display:	block !important;
	}
}
.history-mobile {
	display:	none !important;
	@media screen and (max-width: 480px) {
		display:	block !important;
	}
}

.progress-bar {
	width:				100%;
	height:				10px;
	border:				1px solid rgba(0,0,0,0.4);
	border-radius:		2px;
	background-color:	#ffffff;
	& > div {
		height:	10px;
		background-color:	#1E88E5;
		min-width:			0;
	}
}
/*
<div class="search-input">

	<div>
		<div class="search">
			<div>
				<div>
					<span class="fas fa-search"></span>
				</div>
				<div>
					<input type="text" placeholder="Search" ng-model="main.query"  />
				</div>
			</div>
		</div>
		<div class="options">
			<span class="fas fa-caret-right"></span>
			<span class="fas fa-filter"></span>
		</div>
	</div>
	
</div>
*/


.search-input {
	padding:	5px;
	& > div {
		display:	table;
		width:		100%;
		& > div {
			display:	table-cell;
			
			&.search {
				& > div {
					border-radius:	15px;
					border:			1px solid #999999;
					display:		table;
					width:			100%;
					& > div {
						display:	table-cell;
						padding:	1px;
						&:first-child {
							width:			30px;
							text-align:		center;
						}
						&:last-child {
							padding-right:		10px;
							input {
								width:			100%;
								box-sizing:		border-box;
								border:			0;
								padding:		10px 10px 10px 0;
								box-shadow:		none !important;
								&:focus{
									outline: none;
								}
							}
						}
					}
				}
			}
			&.options {
				padding:	0 5px;
				width:		60px;
				text-align:	right;
				&.large {
					width:	110px;
				}
			}
		}
	}
}




.search-input-simple {
	padding:	5px;
	& > div {
		border-radius:	15px;
		border:			1px solid #999999;
		display:		table;
		width:			100%;
		& > div {
			display:	table-cell;
			padding:	1px;
			&:first-child {
				width:			30px;
				text-align:		center;
			}
			&:last-child {
				padding-right:		10px !important;
				input {
					width:			100% !important;
					box-sizing:		border-box !important;
					border:			0 !important;
					padding:		10px 10px 10px 0 !important;
					box-shadow:		none !important;
					&:focus{
						outline: none;
					}
				}
			}
		}
	}
}