.subaccount-page {
    padding:            10px;
    font-family:        "Lato", sans-serif;
    .account-wrapper {
        display:                flex;
        justify-content:        space-between;
        flex-direction:         row;
        flex-wrap:              wrap;
        margin-bottom:          2%;
        @media (max-width: 768px) {
            flex-direction:     column;
        }
        .account-funding-container,
        .funding-details-container {
            height:                         100%;
            flex:                           1 1 auto;
            border:                         2px solid #bebebe;
            border-width:                   2px 0 0 0;
            .header {
                background:     #ffffff;
                padding:        10px 5px;
                color:          #bebebe;
                border:         2px solid #bebebe;
                border-width:   0 2px 2px 2px;
                text-align:     center;
                font-weight:    600;
                box-sizing:     border-box;
            }
            .maximize,
            .minimize {
                color:          #000000;
                float:          right;
                font-weight:    400;
            }
            .content-container {
                display:                        flex;
                justify-content:                space-between;
                padding:                        24px;
                background:                     #ffffff;
                border:                         2px solid #bebebe;
                border-width:                   0 2px 2px 2px;
                border-bottom-left-radius:      20px;
                border-bottom-right-radius:     20px;
                @media (max-width: 480px) {
                    padding:    8px;
                }
                .content {
                    flex:               1;
                    display:            flex;
                    flex-direction:     column;
                    justify-content:    center;
                    text-align:         center;
                    &:first-child {
                        /* Address */
                        & > :first-child {
                            /* Title */
                            color:              #000000;
                            font-weight:        600;
                            font-size:          20px;
                            margin-bottom:      10px;
                            @media (max-width: 480px) {
                                font-size:      14px;
                            }
                        }
                        & > :last-child {
                            /* Value */
                            color:              #566481;
                            font-weight:        600;
                            font-size:          22px;
                            @media (max-width: 480px) {
                                font-size:      14px;
                            }
                        }
                    }
                    &:last-child {
                        /* The other info on the right */
                        & > .account-number-container { /* why "split" thos? */
                            margin-bottom:  20px;
                            & > :first-child {
								/* Title */
								color:              #000000;
                                font-weight:        600;
                                font-size:          20px;
                                margin-bottom:      10px;
                                @media (max-width: 480px) {
                                    font-size:      14px;
                                }
                            }
                            & > :last-child {
								/* Value */
								color:              #566481;
                                font-weight:        600;
                                font-size:          22px;
                                @media (max-width: 480px) {
                                    font-size:      14px;
                                }
                            }
                        }
                    }
                    /*& > div:first-child {
                        margin-bottom:      35px;
                        color:              #000000;
                        font-weight:        600;
                        font-size:          20px;
                    }
                    & > div:last-child {
                        color:              #566481;
                        font-weight:        600;
                        font-size:          22px;
                    }
                    & > div.split {
                        display:        flex;
                        flex-direction: column;
                        flex:           1 1 auto;
                        &:first-child {
                            margin-bottom:  40px;
                        }
                        & > div:first-child {
                            margin-bottom:      30px;
                            color:              #000000;
                            font-weight:        600;
                            font-size:          20px;
                        }
                        & > div:last-child {
                            color:              #566481;
                            font-weight:        600;
                            font-size:          22px;
                        }
                    }*/
                }
            }
        }
        .account-funding-container {
            margin-right:       2%;
            @media (max-width: 980px) {
                margin-right:   0;
                margin-bottom:  2%;
            }
        }
    }

    .support-ui.subaccount-transactions {
        table.acc-list {
            padding:            0;
            border-spacing:     0;
            thead {
                text-align: center;
                & > tr {
                    & > th {
                        padding:        17px;
                        background:     #cbe0fc;
                        border-top:     2px solid #000000;
                        border-right:   2px solid #000000;
                        border-bottom:  2px solid #000000;
                        &:first-child {
                            border-left:   2px solid #000000;
                        }
                    }
                }
            }
            & > tbody {
                text-align: center;
                & > tr {
                    &.even {
                        background-color: rgba(0, 0, 0, 0.02);
                    }
                    &:hover {
                        background: #42a5f5;
                        & > td {
                            color:      #ffffff !important;
                            transition: .2s ease-in;
                            &:first-child {
                                background:     #42a5f5 !important;
                            }
                        }
                    }
                    & > td {
                        padding:        1rem;
                        text-align:     center;
                        font-size:      1rem;
                        font-weight:    600;
                        &.green {
                            // color:      #49a027;
                            color:      #566481;
                        }
                        &.red {
                            color:      #d32f2f;
                        }
                        &:first-child {
                            border-left:    1px solid #dcdddf !important;
                        }
                        &:last-child {
                            border-right:    1px solid #dcdddf !important;
                        }
                    }
                }
            }
            & > tr > th {
                border-left: 2px solid #dcdddf;
                border-right: 2px solid #dcdddf;
            }
        }
        
    }
}